<template>
  <div>
    <NoticeSlider />
    <NoticeDetail />
  </div>
</template>

<script>
import NoticeSlider from '@/components/notice/slider'
import NoticeDetail from '@/components/notice/notice-detail'
export default {
  components:{
    NoticeSlider,
    NoticeDetail
  },
  data(){
    return {
    }
  }
}
</script>
<style scoped>
</style>