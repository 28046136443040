<template>
  <div>
    <NoticeSlider />
    <NewNotice />
  </div>
</template>

<script>
import NoticeSlider from '@/components/notice/slider'
import NewNotice from '@/components/notice/new-notice'
export default {
  components:{
    NoticeSlider,
    NewNotice
  },
  data(){
    return {
    }
  }
}
</script>
<style scoped>
</style>