<template>
  <div class="warpper">
    <h4>{{ $t('notice.title') }}</h4>
    <div class="notice-content">
      <div class="list" v-for="item in articleList" :key="item.ID">
        <div class="date">
          <div class="day">{{ item.day }}</div>
          <div class="month">{{ item.month }}</div>
        </div>
        <img src="@/assets/image/test.jpg" />
        <div class="bottom">
          <div class="tit">{{ item.post_title }}</div>
          <p>{{ item.subTitle }}</p>
          <div class="action" @click="toDetail(item.ID)">more <img src="@/assets/image/deal/arrow.png" alt="" /></div>
        </div>
      </div>
    </div>
    <div class="more-click">
      <div @click="getArticleList">{{ $t('notice.more') }}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { GbpService } from '@/service/gbp'
const gbpService = new GbpService()
export default {
  components: {},
  props: {},
  data() {
    return {
      page: 1,
      articleList: []
    }
  },
  methods: {
    async getArticleList() {
      await gbpService.getArticle(this.page++, this.$i18n.locale).then(res => {
        res.forEach(item => {
          item.subTitle = item.post_content.replace(/<[^>]+>/g,"").substring(0, 60)
          item.day = moment(item.post_date).format('DD')
          if (this.$i18n.locale === 'zh') {
            item.month = moment(item.post_date).format('MM') + '月'
          } else {
            let month = moment(item.post_date).format('MM')
            switch (month) {
              case '01':
                item.month = 'January'
                break
              case '02':
                item.month = 'February'
                break
              case '03':
                item.month = 'March'
                break
              case '04':
                item.month = 'April'
                break
              case '05':
                item.month = 'May'
                break
              case '06':
                item.month = 'June'
                break
              case '07':
                item.month = 'July'
                break
              case '08':
                item.month = 'August'
                break
              case '09':
                item.month = 'September'
                break
              case '10':
                item.month = 'October'
                break
              case '11':
                item.month = 'November'
                break
              case '12':
                item.month = 'December'
                break
              default:
                item.month = 'January'
            }
          }
        })
        this.articleList = this.articleList.concat(res)
      })
    },
    toDetail(id) {
      this.$router.push({ path: '/noticeDetail', query: { id: id } })
    }
  },
  mounted() {
    this.getArticleList()
  }
}
</script>
<style scoped lang="scss">
.warpper {
  h4 {
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 50px;
    text-align: center;
    margin: 80px 0 38px 0;
  }
  .notice-content {
    width: 1150px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .list {
      flex-basis: 265px;
      margin-right: 30px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(33, 66, 171, 0.15);
      border-radius: 2px;
      position: relative;
      margin-bottom: 20px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 172px;
      }
      .bottom {
        margin: 20px 16px;
        .tit {
          font-size: 18px;
          font-weight: normal;
          color: #000000;
          line-height: 22px;
          margin-bottom: 8px;
        }
        p {
          height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: rgba($color: #000000, $alpha: 0.6);
          line-height: 20px;
          width: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .action {
          margin-top: 16px;
          font-size: 14px;
          font-weight: 400;
          color: #0d73d6;
          line-height: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
            margin-left: 5px;
          }
        }
      }
      .date {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        background: #0d73d6;
        padding: 2px 11px;
        .day {
          font-size: 28px;
          color: #ffffff;
          line-height: 38px;
        }
        .month {
          font-size: 16px;
          font-weight: 400;
          color: rgba($color: #fff, $alpha: 0.6);
          line-height: 22px;
        }
      }
    }
  }
  .more-click {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 60px;
    & > div {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 20px;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .warpper {
    .notice-content {
      width: 95vw;
      .list {
        width: calc((100% - 60px) / 4);
        flex-basis: calc((100% - 60px) / 4);
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .warpper {
    .notice-content {
      width: 95vw;
      .list {
        width: 100%;
        flex-basis: 100%;
        margin-right: 0;
      }
    }
  }
}
</style>
